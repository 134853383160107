import React from 'react';
import { MaintenanceTemplate } from '@svelte/reactify/maintenance/MaintenancePage';
import { AppPageProps } from 'gatsby/types';

type Props = AppPageProps;

export function Head() {
  return (
    <>
      <title>Maintenance</title>
      <meta name="robots" content="noindex"></meta>
    </>
  );
}

const MaintenancePage: React.FC<Props> = () => {
  return <MaintenanceTemplate />;
};

export default MaintenancePage;
