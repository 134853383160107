<script lang="ts">
  import Container from '@svelte/components/atoms/layout/Container.svelte';
  import Heading from '@svelte/components/atoms/typography/Heading/Heading.svelte';
  import Paragraph from '@svelte/components/atoms/typography/Paragraph/Paragraph.svelte';
</script>

<div class="flex-cnt min-h-screen w-screen bg-primary">
  <Container className="flex-cnt flex-col">
    <div class="mb-rhythm3 lg:mb-rhythm6 lg:h-rhythm3">
      <img
        class="h-full w-rhythm8 lg:w-auto"
        src="/logo/alternative_orange.svg"
        alt="Disruptor London logo"
      />
    </div>

    <div class="mb-rhythm3 lg:mb-rhythm6 lg:h-rhythm7">
      <img
        class="h-full w-rhythm8 lg:w-auto"
        src="/images/maintenance.svg"
        alt="Disruptor London logo"
      />
    </div>

    <Heading
      level={1}
      withoutSpacing
      className="mb-rhythm-3 leading-[3.5rem] text-center px-5"
      color="text-pearl">Sorry!</Heading
    >
    <Paragraph color="text-pearl" measure="measure-cozy" className="text-center"
      >We're taking some time out for maintenance. We hope to be back soon.</Paragraph
    >
  </Container>
</div>
